import { faker } from '@faker-js/faker'

const payments = [
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  },
  {
    id: faker.datatype.uuid(),
    time: faker.datatype.datetime().toLocaleString('en-US'),
    number: faker.phone.phoneNumber('(###)-###-####'),
    amount: faker.finance.amount(),
    plan: faker.helpers.arrayElement(['Yearly', 'Monthly'])
  }
]

export default payments
