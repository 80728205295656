<template>
  <div class="main pt-4">
    <h1 class="fz-28 dark-text font-weight-semi-bold mb-5 px-4 px-md-0">
      Payment History
    </h1>
    <v-card class="white elevation-1">
      <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :mobile-breakpoint="600" :headers="headers"
        :items="payments" :page.sync="page" @page-count="pageCount = $event" :items-per-page="18" hide-default-footer>
        <template v-slot:top>
          <v-toolbar flat class="d-sm-none">
            <div class="top d-flex justify-space-between align-center">
              <v-btn class="elevation-0 my-1 text white" @click="toggleOrder">
                <span class="d-inline-block mr-2">Last payment</span>
                <SortIcon />
              </v-btn>
            </div>
          </v-toolbar>
        </template>
        <template v-slot:item.time="{ item }">
          {{ formatDate(item.time) }}
        </template>
        <template v-slot:item.number="{ item }">
          <span class="greyBuoyant">{{ item.number }}</span>
        </template>
        <template v-slot:item.plan="{ item }">
          <span class="greyBuoyant">{{ item.plan }}</span>
        </template>
      </v-data-table>
      <div class="pagination text-center justify-start px-2 py-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import payments from '../mock/payments'
import SortIcon from '../components/SortIcon.vue'
export default {
  name: 'PaymentHistory',
  data: () => {
    return {
      payments: payments,
      sortBy: 'time',
      sortDesc: false,
      headers: [
        {
          text: 'Time',
          align: 'start',
          value: 'time',
          sortable: true
        },
        {
          text: 'Numbers',
          value: 'number',
          sortable: false
        },
        {
          text: 'Amount',
          value: 'amount',
          sortable: true
        },
        {
          text: 'Plan',
          value: 'plan',
          sortable: false
        }
      ],
      page: 1,
      pageCount: 0
    }
  },
  methods: {
    toggleOrder () {
      this.sortDesc = !this.sortDesc
    }
  },
  components: { SortIcon }
}
</script>

<style lang="scss">
.main {
  max-width: 728px;
  margin-right: auto;

  @include breakpoint(medium) {
    max-width: 482px;
    margin-left: auto;
  }

  .pagination {
    background-color: #fff;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }

  .v-pagination {
    justify-content: start !important;
    position: relative;

    li {
      &:first-of-type {
        position: absolute;
        right: 35px;

        @include breakpoint(small) {
          display: none;
        }
      }

      &:last-of-type {
        position: absolute;
        right: 0;

        @include breakpoint(small) {
          display: none;
        }
      }
    }
  }

  .v-pagination__item {
    width: 54px !important;
    height: 34px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%) !important;
    border: 0.8px solid #CED4DA !important;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) !important;
    color: #495057 !important;
    margin: 0 !important;
    border-radius: 0;

    &--active {
      border: 0.8px solid #CED4DA !important;
      box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
      background: #F4F6F8 !important;
    }
  }

  .v-pagination__navigation {
    width: 36px !important;
    height: 34px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%) !important;
    border: 0.8px solid #CED4DA !important;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) !important;
    border-radius: 0;

    .v-icon.v-icon {
      font-size: 16px !important;
    }
  }

  .theme--light.v-icon {
    color: rgb(92, 106, 196);
  }

  .mdi-chevron-left::before {
    content: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.75 6C17.75 5.65482 17.4702 5.375 17.125 5.375H2.38388L6.31694 1.44194C6.56102 1.19787 6.56102 0.802136 6.31694 0.558059C6.07286 0.31398 5.67714 0.31398 5.43306 0.558059L0.433058 5.55806C0.188981 5.80214 0.188981 6.19787 0.433058 6.44194L5.43306 11.4419C5.67714 11.686 6.07286 11.686 6.31694 11.4419C6.56102 11.1979 6.56102 10.8021 6.31694 10.5581L2.38388 6.625H17.125C17.4702 6.625 17.75 6.34518 17.75 6Z' fill='%23667280'/%3E%3C/svg%3E%0A");
    ;
  }

  .mdi-chevron-right::before {
    content: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.25 6C0.25 5.65482 0.529822 5.375 0.875 5.375H15.6161L11.6831 1.44194C11.439 1.19787 11.439 0.802136 11.6831 0.558059C11.9271 0.31398 12.3229 0.31398 12.5669 0.558059L17.5669 5.55806C17.811 5.80214 17.811 6.19787 17.5669 6.44194L12.5669 11.4419C12.3229 11.686 11.9271 11.686 11.6831 11.4419C11.439 11.1979 11.439 10.8021 11.6831 10.5581L15.6161 6.625H0.875C0.529822 6.625 0.25 6.34518 0.25 6Z' fill='%23667280'/%3E%3C/svg%3E%0A");
  }

  .v-data-table__mobile-row__header {
    display: none;
  }

  .v-data-table__mobile-row__cell {
    text-align: left;
    width: 100%;
  }

  .v-data-table__mobile-table-row {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5px;
    column-gap: 25px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding: 16px;
  }

  .v-data-table>.v-data-table__wrapper .v-data-table__mobile-row {
    min-height: auto !important;
    padding: 0;

    &:nth-of-type(1) {
      order: 1;
    }

    &:nth-of-type(2) {
      order: 3;
    }

    &:nth-of-type(3) {
      order: 2;
    }

    &:nth-of-type(4) {
      order: 4;
    }
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
  .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child {
    @include breakpoint(small) {
      border-bottom: 0;
    }
  }

  .v-data-table-header.v-data-table-header-mobile {
    display: none;
  }
}
</style>
