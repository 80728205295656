<template>
  <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M0.734835 5.85984C0.881282 5.71339 1.11872 5.71339 1.26517 5.85984L4 8.59467L6.73483 5.85984C6.88128 5.71339 7.11872 5.71339 7.26517 5.85984C7.41161 6.00628 7.41161 6.24372 7.26517 6.39016L4.26516 9.39017C4.11872 9.53661 3.88128 9.53661 3.73484 9.39017L0.734835 6.39017C0.588388 6.24372 0.588388 6.00628 0.734835 5.85984Z"
      fill="#667280" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M0.734835 4.14016C0.881282 4.28661 1.11872 4.28661 1.26517 4.14016L4 1.40533L6.73483 4.14016C6.88128 4.28661 7.11872 4.28661 7.26517 4.14016C7.41161 3.99372 7.41161 3.75628 7.26517 3.60984L4.26516 0.609835C4.11872 0.463388 3.88128 0.463388 3.73484 0.609835L0.734835 3.60984C0.588388 3.75628 0.588388 3.99372 0.734835 4.14016Z"
      fill="#667280" />
  </svg>
</template>

<script>
export default {
  name: 'SortIcon'
}
</script>

<style lang="scss" scoped>
</style>
